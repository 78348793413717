































import { Component, Prop, Vue } from 'vue-property-decorator'

import { OrderStatus } from '@/store/types'

@Component
export default class SystemMessageCard extends Vue {
  @Prop({ default: false })
  private isAuth!: boolean

  @Prop({ default: false })
  private isRecurrent!: boolean

  @Prop({ required: true })
  private orderStatus!: OrderStatus

  @Prop({ default: true })
  private onlyCard!: boolean

  private get title() {
    return this.orderStatus === OrderStatus.PAID ? 'Оплата прошла успешно' : 'Упс, здесь ничего нет'
  }

  private get type() {
    return this.orderStatus === OrderStatus.PAID ? 'success' : 'error'
  }
}
